<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import Helper from '@/helpers/helper';
import Swal from 'sweetalert2'

import router from '../../../index'


import { ref } from "vue";


export default {
    setup() {
       
       const doesChange = ref(false);



       return {

           doesChange,
     
       };
   },
    page: {
        title: "Profil",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
    },
    data() {
        return {
            me: null,
            loading: false,
            status: 201,
            imageUrl: '',
            selectedImage: null,
            users: [],
            user_id: "",
            errors: [],
            changeLoading: false
        };
    },
    methods: {
        loadList() {
            fetch(Helper.route('auth/me'), Helper.requestOptions())
            .then(response => {
                Helper.tokenChecker(response.status)
                return response.json()
            })
            .then(data => {
                console.log(data);
                this.me = data;
                this.imageUrl = Helper.STORAGE_URL + data.entity.user.image; // Assuming the image URL is provided in the data
            });
        },
        loadUsers() {
            fetch(Helper.route('users'), Helper.requestOptions())
            .then(response => {
                Helper.tokenChecker(response.status)
                return response.json()
            })
            .then(data => {
                this.users = data.data
            })
        },
        onImageChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.selectedImage = file;
                const reader = new FileReader();
                reader.onload = e => {
                    this.imageUrl = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        updateImage() {
            // Implement the image update logic here
            console.log('Image updated:', this.selectedImage);
            // Example of how to send the selected image to the server
            const formData = new FormData();
            formData.append('image', this.selectedImage);

            fetch(Helper.route('auth/update-image'), Helper.requestOptions("POST", formData)).then(response => {
              //console.log(Helper.requestOptions());  
              if (response.status == 200) {
                  Swal.fire("Image modifier !", "L'image a été modifié avec succès.", "success");
                    console.log('Image successfully updated');
                } else {
                    console.log('Error updating image', response.json());
                }
            });
        },

        changeCDN(){
            this.changeLoading = true
            this.errors = []
            const formData = new FormData();
            formData.append('user_id', this.user_id);

            fetch(Helper.route('change_cdn'), Helper.requestOptions("POST", formData)).then(response => {
              //console.log(Helper.requestOptions());  
              this.changeLoading=false
              if (response.status == 200) {
                this.doesChange = false
                  Swal.fire("Responsable modifier !", "L'image a été modifié avec succès.", "success");
                  localStorage.removeItem(Helper.CLIENT_TOKEN)
                  localStorage.removeItem(Helper.WHO_CONNECTED)
                  router.push('/login'); 
                } else {
                    const data = response.json()
                    console.log('Error', data);
                    this.errors.user_id = Helper.getError(data, "user_id")
                }
            });
        },

        triggerChangeModal(){
            this.doesChange = true
        }
    },
    mounted() {
        this.loadList();
        this.loadUsers();
    },
};
</script>

<template>
<Layout>
    <div class="row" v-if="me != null">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-0 fw-bold">Profil</h4>
                    <div class="text-center mt-4">
                            <img :src="imageUrl" alt="Profile Image" class="rounded-circle" style="width: 150px; height: 150px; object-fit: cover;">
                            <div class="mt-3">
                                <b-button variant="primary" @click="() => $refs.fileInput.click()">Changer image</b-button>
                                <input type="file" ref="fileInput" @change="onImageChange" accept="image/*" style="display: none;">
                                <b-button v-if="selectedImage" class="mx-3" variant="success" @click="updateImage">Mettre à jour</b-button>
                            </div>
                        </div>
                </div>
            </div>
            <div class="row bg-white m-0">
                <div class="col-lg-6 p-0">
                    <div class="h-100 bg-light border-right p-3">
                        <h5 class="text-black">{{ me.entity.type == "CDN" ? "CDN" : (me.entity.type == "PATNER" ? "Partenaire" : "Secteur") }}</h5>
                        <hr class="m-0 bg-primary mb-3">
                        <div>
                            <label for="" class="font-size-11">Intitulé de l'entité</label>
                            <p class="font-size-13 text-primary">{{ me.entity.name }}</p>
                        </div>
                        <div class="mt-3">
                            <label for="" class="font-size-11">Email</label>
                            <p class="font-size-13 text-primary">{{ me.entity.email }}</p>
                        </div>
                        <div class="mt-3">
                            <label for="" class="font-size-11">Téléphone</label>
                            <p class="font-size-13 text-primary">{{ me.entity.phone }}</p>
                        </div>
                        <div class="mt-3">
                            <label for="" class="font-size-11">Adresse</label>
                            <p class="font-size-13 text-primary">{{ me.entity.address }}</p>
                        </div>
                        <div class="mt-3">
                            <label for="" class="fw-bolder font-size-11">Description</label>
                            <p class="font-size-13">{{ me.entity.description }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 p-0">
                    <div class="h-100 shadow-sm border-right p-3">
                        <h5 class="text-black">Actuel responsable</h5>
                        <hr class="m-0 bg-primary mb-3">
                        <div>
                            <label for="" class="font-size-11">Nom</label>
                            <p class="font-size-13 text-primary">{{ me.entity.user.lastname }}</p>
                        </div>
                        <div class="mt-3">
                            <label for="" class="font-size-11">Prénom</label>
                            <p class="font-size-13 text-primary">{{ me.entity.user.firstname }}</p>
                        </div>
                        <div class="mt-3">
                            <label for="" class="font-size-11">Email</label>
                            <p class="font-size-13 text-primary">{{ me.entity.user.email }}</p>
                        </div>
                        <div class="mt-3">
                            <label for="" class="font-size-11">Téléphone</label>
                            <p class="font-size-13 text-primary">{{ me.entity.user.phone }}</p>
                        </div>

                        <div>
                            <!-- Affiche le bloc uniquement si me.entity == 'CDN' -->
                            <div v-if="me.entity.name === 'CDN'">
                                <div class="col-md-6 mb-3">
                                    <label for="" class="font-size-16">Changer le CDN</label>
                                    <select required v-model="user_id" class="form-control">
                                    <option value="">Selectionnez le responsable</option>
                                    <option v-for="user in users" :key="user.id" :value="user.id">
                                        {{ user.lastname + " " + user.firstname }}
                                    </option>
                                    </select>
                                    <span class="text-danger mt-2" v-if="errors.user_id">{{ errors.user_id }}</span>
                                </div>

                                <div class="text-center mx-auto">
                                    <div v-if="!changeLoading" class="mt-3 text-center">
                                    <b-button variant="danger" @click="triggerChangeModal()">Changer</b-button>
                                    </div>

                                    <div v-else class="mt-3 text-center">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>


    <b-modal
        v-model="doesChange"
        id="modal-center"
        title="Changer le responsable CDN"
        title-class="font-18"
        hide-footer
        >
        <p class="mb-2">
            Voulez-vous vraiment changer le responsable de la CDN ?
        </p>
        <div class="hstack mt-2" v-if="!loading">
          <button class="btn btn-dark ms-auto" @click="doesChange = false">Annuler</button>
          <button class="ms-3 btn btn-danger" @click="changeCDN()">Supprimer</button>
        </div>
        <div class="text-center" v-else>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
    </b-modal>


</Layout>
</template>

<style scoped>
.rounded-circle {
    border-radius: 50%;
}
</style>
